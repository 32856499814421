@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500;700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}
:root {
  --color-primary: hsl(0, 0%, 8%);
  --color-secondary: hsl(0, 0%, 14%);
  --color-accent: hsl(153, 71%, 59%);
  --color-gray: hsl(0, 0%, 85%);
  --color-white: hsl(0, 0%, 100%);
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-primary);
}
h1,
h2,
h3 {
  color: var(--color-white);
  font-weight: 700;
  margin: 2rem 0 0 0;
  text-align: center;
}
h1 {
  font-size: 5.5rem;
  line-height: 88px;
  letter-spacing: -2.5px;
}
h2 {
  font-size: 3rem;
  line-height: 56px;
  letter-spacing: -1.5px;
}
h3 {
  font-size: 1.5rem;
  line-height: 32px;
}

p {
  color: var(--color-gray);
  margin: 3rem 0;
  font-weight: 500;
  font-size: medium;
  line-height: 3rem;
  text-align: center;
}

hr {
  border: 1px solid var(--color-white);
  margin: 5rem 0;
}

a {
  color: var(--color-white);
  text-decoration: underline;
  text-decoration-color: var(--color-accent);
}

a:hover {
  color: var(--color-accent);
}

/* DESKTOP */
@media screen and (min-width: 1080px) {
  hr {
    border-bottom: 0px;
  }
}
