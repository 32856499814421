.projects {
  color: var(--color-white);
  font-size: xx-large;
}

.project-header {
  all: unset;
  margin: 0 0 3rem 0;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-contact {
  font-size: large;
}

.project-thumbnail {
  all: unset;
  width: 100%;
}

.project-name {
  font-weight: bold;
  margin: 1rem 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.project-tools {
  color: var(--color-gray);
  font-size: medium;
  margin: 1rem 0;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.project-tool {
  padding: 1rem 1rem 1rem 0;
}

.project-urls {
  font-size: 2.3rem;
  text-transform: uppercase;
  margin: 1rem 0 6rem 0;
  display: flex;
  justify-content: space-between;
}

.project-urls:hover,
.project-contact:hover {
  color: var(--color-accent);
}

/* TABLET */
@media screen and (min-width: 768px) {
  .project-header {
    font-size: 6rem;
    font-weight: bold;
    align-items: flex-start;
  }
  .project-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 3rem;
  }
  .project-tools {
    font-size: x-large;
  }
  .project-urls {
    margin-bottom: 2rem;
  }
}

/* DESKTOP */
@media screen and (min-width: 1080px) {
  .project-header {
    font-size: 4rem;
    align-items: flex-start;
    margin-bottom: 5rem;
  }
  .project-wrapper {
    column-gap: 0;
    row-gap: 3rem;
    column-gap: 3rem;
  }
  .project-name {
    font-size: 2rem;
  }
  .project-thumbnail:hover {
    filter: grayscale(0.6) opacity(0.6);
  }
  .project-tools {
    font-size: 1.5rem;
  }
}
