.bookend {
  text-align: center;
}

.icons {
  color: var(--color-white);
  font-size: 4rem;
  padding-bottom: 1rem;
}

i {
  padding: 0 1rem;
}

i:hover,
i:active {
  color: var(--color-accent);
}
h2 a {
  text-decoration: none;
}

/* TABLET */
@media screen and (min-width: 768px) {
  .bookend {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 3rem;
  }
  h2 {
    font-size: 5rem;
    margin-top: 0;
  }
  .icons {
    font-size: 4rem;
  }
}

/* DESKTOP */
@media screen and (min-width: 1080px) {
  h2 a {
    font-size: x-large;
  }
  .icons {
    font-size: 2rem;
  }
}
