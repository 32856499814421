.contact-wrapper {
  padding-top: 5rem;
  width: 66vw;
  margin: auto;

  color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

fieldset {
  border: 0;
}

fieldset:invalid ~ fieldset {
  display: none;
}
form:invalid button {
  opacity: 0.3;
  pointer-events: none;
}

/* input:required {
  border-bottom: 0;
} */

.contact-title {
  font-size: xx-large;
  font-weight: bold;
  text-transform: capitalize;
}

.contact-input:disabled {
  display: none;
}

.contact-input {
  background-color: var(--color-secondary);
  border: 0;
  color: white;
  font-family: "Space Grotesk", sans-serif;
  margin-bottom: 2rem;
  padding: 1rem 0 1rem 2rem;
  text-transform: uppercase;
  width: 100%;
}

.contact-input:focus {
  outline: transparent;
}

.contact-submit {
  all: unset;
  align-self: flex-end;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 2rem;
  text-transform: uppercase;
  padding-bottom: 1.5rem;
  cursor: pointer;
}
.contact-submit:active,
.contact-submit:hover {
  color: var(--color-accent);
}

.border {
  border-bottom: 1px solid var(--color-gray);
}

.border:focus,
.border:hover {
  border-bottom: 1px solid var(--color-accent);
}

/* TABLET */
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .contact-wrapper {
    margin: auto;
    width: 66vw;
  }
  .contact-wrapper p {
    font-size: large;
  }
  .contact-title {
    font-size: 7rem;
  }
  .contact-input {
    font-size: 2rem;
    padding: 2rem 0 2rem 3rem;
  }
  .contact-submit {
    font-size: 2rem;
  }
}

/* DESKTOP */
@media screen and (min-width: 1080px) {
  .contact-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    align-items: start;
    column-gap: 12rem;
  }
  .contact-details {
    grid-area: 1 / 1 / 2 / 2;
  }
  .contact-form {
    text-align: end;
    grid-area: 1 / 2 / 2 / 3;
  }
}
