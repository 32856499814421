.skills {
  margin: 0rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.skill {
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
}

.skill-name {
  color: var(--color-white);
  font-weight: bold;
  font-size: xx-large;
  line-height: 5rem;
}
.skill-exp {
  color: var(--color-gray);
  font-size: large;
}

/* TABLET */
@media screen and (min-width: 768px) {
  .skills {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 2rem;
  }
  .skill {
    text-align: left;
    margin-bottom: 2rem;
  }
  .skill-name {
    font-size: 6rem;
    padding-bottom: 1rem;
  }
  .skill-name {
    font-size: 4rem;
  }
}

/* DESKTOP */
@media screen and (min-width: 1080px) {
  .skills {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 5rem;
  }
  .skill-exp {
    font-size: large;
  }
}
