.wrapper {
  margin: 2rem;
}
footer {
  background-color: var(--color-secondary);
  margin-top: 4rem;
  padding: 0 2rem;
}

/* TODO: sort bg images out */
.background-rings {
  background-image: url(/public/images/pattern-rings.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-x: -30rem;
}

/* TABLET */
@media screen and (min-width: 768px) {
  .wrapper {
    margin: 3rem;
  }
  footer {
    padding: 0 3rem;
  }
}

/* DESKTOP */
@media screen and (min-width: 1080px) {
  .wrapper,
  footer section {
    width: 66vw;
    margin: auto;
  }

  footer hr {
    margin-bottom: 1rem;
  }

  footer .bookend {
    padding-bottom: 0;
  }
}
