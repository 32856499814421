header {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
}

.header-photo img {
  width: 66%;
  margin-top: 2rem;
  border: 0.5rem solid var(--color-accent);
  border-radius: 50%;
}
.highlight {
  text-decoration: underline;
  text-decoration-color: var(--color-accent);
  text-underline-offset: 1rem;
}
.contact {
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin: 0 0 2rem 0;
  text-underline-offset: 1.5rem;
}

.contact:hover {
  color: var(--color-accent);
}

/* TABLET */
@media screen and (min-width: 768px) {
  header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 6rem;
  }
  .header-blurb {
    grid-area: 1 / 1 / 2 / 2;
  }
  .header-photo {
    grid-area: 1 / 2 / 2 / 3;
  }
  h1,
  p {
    text-align: left;
  }
}

/* DESKTOP */
@media screen and (min-width: 1080px) {
  header {
    align-items: center;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    text-align: left;
  }
  .header-blurb h1 {
    font-size: 5rem;
  }
  .header-blurb p {
    font-size: 1.4rem;
    margin: 5rem 0;
  }
  .header-photo img {
    width: 25vw;
  }
  .contact {
    font-size: 1.4rem;
  }
  .highlight {
    text-underline-offset: 0.8rem;
  }
}
